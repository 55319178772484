import { useQuery } from '@apollo/client'
import Alert from 'components/feedback/Alert'
import Spinner from 'components/feedback/Spinner'
import { groupEssentialsQuery } from 'lib/queries'
import { each } from 'lodash'
import { useRouter } from 'next/router'
import { createContext, ReactNode, useContext, VFC } from 'react'
import { useIntl } from 'react-intl'

const GroupContext = createContext({})

type StylesProps = {
  styles: Record<string, string>
}

const Styles: VFC<StylesProps> = ({ styles }) => {
  const styleAttribute: Array<string> = []

  if (styles) {
    each(styles, (v, k) => {
      if (v && k !== '__typename') {
        styleAttribute.push(`--${k}: ${v}`)
      }
    })
  }

  return (
    <style
      dangerouslySetInnerHTML={{
        __html: `body { ${styleAttribute.join(';\n')} }`,
      }}
    />
  )
}

type NoGroupErrorProps = {
  message: string
}

const NoGroupError: VFC<NoGroupErrorProps> = ({ message }) => {
  const { formatMessage } = useIntl()
  return (
    <Alert
      className="m-4"
      title={formatMessage({ id: 'error.couldNotLoadPage' })}
      type="error"
      showIcon
      description={message}
    />
  )
}

type GroupProviderProps = {
  children: ReactNode
  hasServerSideProps: boolean
  groupId?: string
  locale?: string
}

export const GroupProvider: VFC<GroupProviderProps> = ({
  children,
  hasServerSideProps,
  groupId,
  locale,
}) => {
  const { asPath } = useRouter()
  const { formatMessage } = useIntl()

  const isDashboard = asPath.includes('/dashboard')
  const noGroup = groupId
    ? formatMessage({ id: 'error.noGroupFound' }, { slug: groupId })
    : formatMessage({ id: 'error.noGroup' })

  const { data, loading, error, refetch } = useQuery(groupEssentialsQuery, {
    skip: !groupId || !hasServerSideProps,
    variables: { id: groupId, locale },
  })

  if (!hasServerSideProps) {
    return <>{children}</>
  }

  if (!groupId) {
    if (isDashboard) {
      return <NoGroupError message={noGroup} />
    }
    return <>{children}</>
  }

  if (loading) {
    return (
      <div className="h-screen flex justify-center items-center">
        <Spinner />
      </div>
    )
  }

  if (error) {
    return <NoGroupError message={error.message} />
  }

  const currentGroup = data?.group

  if (isDashboard && !currentGroup) {
    return <NoGroupError message={noGroup} />
  }

  // @TODO: does passing `loading` even make sense here?
  // we're returning the spinner when loading is true
  // so by the time it gets to here it's always false

  return (
    <GroupContext.Provider
      value={{ currentGroup, loading, isDashboard, refetch }}
    >
      <Styles styles={!isDashboard && currentGroup && currentGroup.styles} />
      {children}
    </GroupContext.Provider>
  )
}

export const useCurrentGroup = () => {
  return useContext(GroupContext)
}

export default GroupContext
